import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MDXProvider } from "@mdx-js/react";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        maxWidth: 900,
        margin: '0 auto',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    H1: {
        fontSize: '2.0em',
        fontWeight: 700,
        letterSpacing: 1
    },        
    LI: {
        marginBottom: '0'
    }
}));
    
 const MdxLayout = ({children}) => {
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <MDXProvider
                className={classes.root}
                components={{
                    h1: props => <h1 {...props} className={classes.H1} >{ props.children }</h1>,
                    li: props => <li {...props} className={classes.LI} />,
                }}
            >
            {children}
            </MDXProvider>
        </div>
    );
}

export default MdxLayout